<template>
	<div
		class="top-line	"
	>
		<div
			class="mt-30 width-480-pc ma-auto flex-column full-height overflow-y-auto  box box-shadow"
		>
			<div>
				<div class="justify-space-between bg-base pa-10 items-center">
					<h6 class=" ">내 정보</h6>
					<button class="btn-round btn-outline btn-ss" @click="onConfirm('modify')">정보수정</button>
				</div>
				<ul
					class="box-mypage-list shadow ul-under-line size-px-14 "
				>
					<li
						class="pa-10 justify-space-between"
					>
						<span>아이디</span>
						<span>{{ user.member_id }}</span>
					</li>
					<li
						class="pa-10 justify-space-between bg-gray-light"
						@click="onConfirm('password')"
					>
						<span>비밀번호 변경</span>
						<button><v-icon>mdi-chevron-right</v-icon></button>
					</li>
					<li
						class="pa-10 justify-space-between"
					>
						<span>이름</span>
						<span>{{ user.member_name }}</span>
					</li>
					<li
						class="pa-10 justify-space-between"
					>
						<span>연락처</span>
						<span>
							{{ user.member_tell }}
						</span>
					</li>
					<li
						v-if="false"
						class="pa-10 justify-space-between bg-gray-light"
						@click="$bus.$emit('to', {name: 'PhoneNumber'})"
					>
						<span>연락처 변경</span>
						<button><v-icon>mdi-chevron-right</v-icon></button>
					</li>
					<li
						class="pa-10 justify-space-between"
					>
						<span>이메일</span>
						<span>{{ user.member_email }}</span>
					</li>
					<li
						class="pa-10 "
					>
						<div class=" text-right">{{ user.member_post }} {{ user.member_addr1 }}</div>
					</li>
					<li
						class="pa-10 "
					>
						<div class=" text-right">{{ user.member_addr2 }}</div>
					</li>
					<li
						v-if="false"
						class="pa-10 justify-space-between bg-gray-light"
						@click="$bus.$emit('to', { name: 'Shipping'})"
					>
						<span>주소록</span>
						<button><v-icon>mdi-chevron-right</v-icon></button>
					</li>
					<li
						class="pa-10 "
					>
						<div class=" text-right">
							<button
								class="btn-round btn-danger btn-ss "
								@click="onOut"
							>회원 탈퇴</button>
						</div>
					</li>
				</ul>
			</div>

		</div>

		<div
			v-if="false"
			class="bottom"
		>
			<button
				v-if="!view.password"

				class="btn btn-identify"

				@click="logout"
			>로그아웃</button>
		</div>

		<div
			v-if="is_on_confirm"
			class="position-fixed-full"
		>
			<div
				style="position: absolute; width: 100%; height: 100%; background-color: black; opacity: 0.5"
			></div>
			<div
				style="position: relative; height: 100%; background: none; display: flex; flex-direction: column; justify-content: center; z-index: 9999"
				class="color-white text-center size-px-24"
			>

				<iframe
					v-show="true"
					:src="nice_url"
					class="full-height full-width"
				></iframe>
			</div>
		</div>

		<PopupConfirm
			v-if="is_on_out"
			@click="onConfirm('out')"
			@cancel="is_on_out = false"
		>
			<template
				v-slot:title
			>회원탈퇴</template>
			<template
				v-slot:main-txt
			>
				탈퇴 확인 후 30일 이내 접속 시 탈퇴처리가 완료 되지 않으며 로그인 시 기존 등급으로 재이용 가능하십니다.
				<br/>
				<br/>
				단, 30일 이내 미접속시 탈퇴 처리가 완료되어 개인정보가 모두 삭제되고 복구가 되지 않으니 이점에 유의하시기 바랍니다.
				<br/>
				<br/>
				탈퇴를 원하시면 확인을 눌러주세요!!
			</template>
		</PopupConfirm>

		<PopupConfirm
			v-if="in_on_out_result"
			@click="postOut"
			@cancel="postOut"
			:not_cancel="true"
		>
			<template
				v-slot:title
			>회원탈퇴</template>
			<template
				v-slot:main-txt
			>
				아이디: {{ user.member_id }}
				<br/>
				이름: {{ user.member_name }}
				<br/>
				<br/>
				회원 탈퇴에 동의합니다.
			</template>
		</PopupConfirm>
	</div>
</template>

<script>

import PopupConfirm from "../Layout/PopupConfirm";
export default{
	name: 'Mypage'
	,props: ['user']
	,components: {PopupConfirm  }
	,data: function(){
		return {
			program: {
				name: '마이페이지'
				,code: 'mypage'
				,wrap: 'mypage'
				,top: false
				,title: true
				,bottom: true
				,class: 'blue'
				, top_line: true
			}
			,item: {
				member_id: '아이디'
				,member_phone: '연락처'
				,member_email: '이메일'
				,member_post: '우편번호'
				,member_addr1: '주소'
				,member_addr2: '상세 주소'
			}
			,view: {
				password: false
			}
			, is_on_confirm: false
			, nice_url: ''
			, nice_type: ''
			, is_on_out: false
			, in_on_out_result: false
		}
	}
	,methods: {
		logout: function(){
			if(confirm('로그아웃 하시겠습니까?')){
				sessionStorage.removeItem(process.env.VUE_APP_NAME + 'T')
				sessionStorage.removeItem(process.env.VUE_APP_NAME + 'T2')
				this.$router.push({ name: 'Login'})
			}
		}
		,toOrderList: function(type){
			this.$bus.$emit('to', { name: 'OrderList', params: {type: type}})
		}
		, toRegularList: function(){
			this.$bus.$emit('to', { name: 'OrderRegular'})
		}
		,setNotify: function({ type, message }){
			this.$bus.$emit('notify', { type: type, message: message })
		}
		,toQnA: function(){
			this.$bus.$emit('to', { name: 'CustomerCenter', params: { b_code: 'b_qna'}})
		}
		,toNotice: function(){
			this.$bus.$emit('to', { name: 'CustomerCenter', params: { b_code: 'b_notice'}})
		}
		,toReview: function(){
			this.$bus.$emit('to', {name: 'CustomerCenter', params: { b_code: 'b_after' }})
		}
		,toModify: function(){
			this.$bus.$emit('to', {name: 'Modify'})
		}
		, toCredit: function(){
			this.$bus.$emit('to', { name: 'CreditList'})
		}
		, niceResult: function(e){
			try {
				let domains = process.env.VUE_APP_DOMAIN.split('|')
				let t = false
				for(let i = 0; i < domains.length; i++){
					if(e.origin.indexOf(domains[i]) > -1){
						t = true
					}
				}
				if(t){
					let data = e?.data
					if(data && typeof data == 'string'){
						data = JSON.parse(data)
						if(data.type == 'nice_result'){
							if(data.result){
								if(this.nice_type == 'modify'){
									this.toModify()
								}else if(this.nice_type == 'password'){
									this.toPassword()
								}else if(this.nice_type == 'out'){
									this.is_on_out = false
									this.in_on_out_result = true
								}
							}else{
								throw data.message
							}
						}
					}
				}
			}catch (e) {
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				// this.is_on_confirm = false
			}
		}
		, onConfirm: function(type){
			this.nice_type = type

			let baseUrl = this.$baseUrl

			this.nice_url = baseUrl + '/nice/request?type=' + type + '&P=' + sessionStorage.getItem(process.env.VUE_APP_NAME + 'AT')
			// this.is_on_confirm = true

			window.open(this.nice_url, 'nice', 'width=320px')
		}
		, toPassword: function(){
			this.$bus.$emit('to', {name: 'Password'})
		}
		, onOut: function(){
			if(this.user.member_point > 0){
				this.$bus.$emit('notify', { type: 'error', message: '보유한 포인트를 모두 사용 후 탈퇴가능합니다'})
			}else{
				this.is_on_out = true
			}
		}
		, postOut: async function(){
			try{
				const result = await this.$request.init({
					method: 'post'
					, url: 'member/postOut'
					, data: {
						uid: this.user.uid
					}
				})

				if(result.success){
					this.$bus.$emit("logout")
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message })
				}
			}catch(E){
				console.log(E)
				this.$bus.$emit('notify', { type: 'error', message: E })
			}
		}
	}
	, destroyed() {
		window.removeEventListener('message', this.niceResult)
	}
	,created: function(){
		this.$bus.$emit('onLoad', this.program)
		window.addEventListener('message', this.niceResult)
	}
	,watch: {
	}
}
</script>

<style>
.box-mypage-list {}
.box-mypage-list.shadow { box-shadow: 0px 3px 5px rgb(0, 0, 0, .3) }
.btn-mypage-add {
	border: 1px solid #ddd;
	padding: 2px 2px 2px 7px;
	font-size: 11px
}
</style>